<template>
  <div class="bg-warning-main rounded-xl px-6 py-2 lg:px-8">
    <p>Stran se ne more povezati na strežnik.</p>
    <p>Prosimo poskusite čez nekaj minut.</p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
